import {fetchJSONByGet, fetchJSONByPost} from './index.js'

// 获取渠道列表
export const fetchSourcesApi = (parent=true) => fetchJSONByGet('/adms_api_v0/chart/gaChannels', {parent}, {trigger: false})
// 获取比较日线图
export const fetchChartsApi = (params, signal=null) => fetchJSONByGet('/adms_api_v0/chart/v2', params, {signal})
// 获取报表
export const fetchDailyFormsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/chart/report', params, {trigger})
// 导出
export const exportRecordsApi = (params) => fetchJSONByGet('/adms_api_v0/chart/report/excel', params, {responseType: 'blob'})
// 获取账号花费/余额列表
export const fetchCostAndBalanceListApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/order/search', params, {trigger})
// 导出账号花费/余额
export const exportCostAndBalanceListApi = (params) => fetchJSONByGet('/adms_api_v0/order/search/excel', params, {responseType: 'blob'})
// 商品销售列表
export const fetchSalesApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/order/sales/report', params, {trigger})
// 导出商品销售列表
export const exportSalesApi = (params) => fetchJSONByGet('/adms_api_v0/order/sales/report/export', params, {responseType: 'blob'})

// 测款数据-列表
export const fetchNewtestListApi = (params, trigger = false, test=true) => fetchJSONByGet('/adms_api_v0/facebook/ad/ingishts/report', {...params, test}, {trigger})
// 导出-测款数据-列表
export const exportNewTestListApi = (params, test=true) => fetchJSONByGet('/adms_api_v0/facebook/ad/ingishts/report/export', {...params, test}, {responseType: 'blob'})

// Campaign-LTV
export const fetchCampaignsDataApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/report/campaign/page', params, {trigger})
export const fetchLTVDataApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/report/ltv/page', params, {trigger})
export const exportCampaignsDataApi = (params) => fetchJSONByGet('/adms_api_v0/report/campaign/excel', params, {responseType: 'blob'})
export const exportLTVDataApi = (params) => fetchJSONByGet('/adms_api_v0/report/ltv/excel', params, {responseType: 'blob'})
// JSCX
export const fetchJSCXDataApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/adscom/report', params, {trigger})
export const fetchAdsResourcesApi = (type) => fetchJSONByGet(`/adms_api_v0/adscom/resources/${type}`)
export const fetchJSCXTermsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/adscom/termReport', params, {trigger})
export const fetchAllDomainsApi = (params) => fetchJSONByGet('/adms_api_v0/adscom/listAllDomains', params)
export const fetchJSCXEstWatchApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/adscom/est/watch', params, {trigger})
// JSCX Plan
export const fetchJSCXPlanApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/adscom/pageAdsetAdjustPlan', params, {trigger})
export const confirmJSCXPlanApi = (id) => fetchJSONByPost(`/adms_api_v0/adscom/confirmAdjust/${id}`)
export const batchConfirmJSCXPlansApi = (ids) => fetchJSONByPost(`/adms_api_v0/adscom/batchConfirmAdjust`, {ids})
// JSCX Domain Direciton
export const fetchJSCXDomainDirectionsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/adscom/pageDomainDirection', params, {trigger})
export const addJSCXDomainDirectionApi = (body) => fetchJSONByPost(`/adms_api_v0/adscom/domainDirection`, body)
export const updateJSCXDomainDirectionApi = (id, body) => fetchJSONByPost(`/adms_api_v0/adscom/domainDirection/${id}`, body)
export const delJSCXDomainDirectionApi = (id) => fetchJSONByPost(`/adms_api_v0/adscom/domainDirection/${id}/delete`)
// JSCX Term Direction
export const fetchJSCXTermDirectionsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/adscom/pageTermDirection', params, {trigger})
export const addJSCXTermDirectionApi = (body) => fetchJSONByPost(`/adms_api_v0/adscom/termDirection`, body)
export const updateJSCXTermDirectionApi = (id, body) => fetchJSONByPost(`/adms_api_v0/adscom/termDirection/${id}`, body)
export const delJSCXTermDirectionApi = (id) => fetchJSONByPost(`/adms_api_v0/adscom/termDirection/${id}/delete`)
export const fetchDirectionsApi = () => fetchJSONByPost('/adms_api_v0/adscom/directions')
// JSCX CF Offers
export const fetchJSCXCFOffersApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/adscom/listCFOfferReports', params, {trigger})
export const updateJSCXCFOfferStatusApi = (id, flag) => fetchJSONByPost(`/adms_api_v0/adscom/autoAdjustStatus/${id}/${flag}`)
// JSCX Term Adjust
export const fetchJSCXListTermAdjustPlanApi = (params) => fetchJSONByPost('/adms_api_v0/adscom/listTermAdjustPlan', null, {...params})
export const confirmJSCXTermAdjustPlanApi = (id, date) => fetchJSONByPost(`/adms_api_v0/adscom/confirmAdjustTerm/${id}/${date}`)







